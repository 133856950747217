import { lightGreen } from '@mui/material/colors';
import { borderLeftColor } from '@mui/system';
import { Button, Table, Space, Modal, Input, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { SendOutlined, CheckOutlined, CloseOutlined, MailOutlined, UserOutlined, TeamOutlined, CopyOutlined } from "@ant-design/icons";
import { Hidden } from '@mui/material';
import { useDispatch, useSelector, } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import telegramtokenpost from "../store/services/Telegramtokenpost";
import telegramtokendelete from '../store/services/Telergamtokendelete';
import telegramtokenlist from '../store/services/Telegramtokenlist';
import telegramcheck from "../store/services/Telegramtokenschech"
import telegrastatus from "../store/services/Telegramstatus"
import { Checkbox, Select } from 'antd';
import { size } from 'lodash';
import USerSocedit from "../store/services/usersocaledit";
import TelegrammsgAuth from "../store/services/Telegramalaermsg"
import Header from '../component/Header';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from "react-icons/fi";
import { fa0 } from '@fortawesome/free-solid-svg-icons';
const TelegramAlerts = () => {

    const [adduseradmin, setAdduseradmin] = useState(false);
    const [alertsendemodel, setAlertsendemodel] = useState(true);
    const [btnname, setbtname] = useState("Submit")
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [telegramtoken, setTelegramtoken] = useState("")
    const [telegrmlist, setTelegramlist] = useState([])
    const [deletetokenlist, setdeletetokenlist] = useState("")
    const [selectitmes, setSelecteditmes] = useState([])
    const https = useSelector(state => state.https)
    const telegrmtokenpost = useSelector(state => state.telegrmtokenpost)
    const [changestatuss, setchanestatus] = useState(true)
    const [deleteitems, setDeleteitmes] = useState(false)
    const [telegramactivelist, setTelegramactivelist] = useState([])
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(59)
    const [runing, setRuning] = useState(false)

    const [minutesmobile, setMinutesmobile] = useState(0);
    const [secondsmobile, setSecondsmobile] = useState(59)
    const [runingmobile, setRuningmobile] = useState(false)

    const [resendmobileotp, setResendmobileotp] = useState(false)
    const [resendemaileotp, setResendemaileotp] = useState(false)

    const [count, setCount] = useState(0);
    const [responsmsg, setResponsmsg] = useState("")
    const [telegramuser, setTelegramuser] = useState("")
    const [telgramstatus, setTelegramstatus] = useState("")
    const [telegramid, setTelegramid] = useState("")
    const[telegramstatustype,setTelegramstatustype] = useState("")

    const [chatid, setChatid] = useState("")
    const [telegramChannelid, setTelegramChannelid] = useState("")
    const [telegramGroupid, SetTelegramGroupid] = useState("")
    const [unabalealert, setUnabalert] = useState(false)

    const [inputvalue, setInputvalue] = useState("")
    const [copycode,setCopycode] = useState(true)
    const userId = localStorage.getItem('userid');

    const postBotapi = () => {
        debugger
        let usserid = userId
        let token = telegramtoken
        telegramcheck.telegramtokencheck(token).then((apires) => {
            debugger
            if (apires.data.data == null) {
                const paramss = { UserId: usserid, TelegramToken: token };
                telegramtokenpost.telegramtoken(paramss).then((apires) => {

                    toast.info(apires.data.message, {
                        position: "top-center"
                    })
                    setAdduseradmin(false)
                    setTelegramtoken("")
                })
                    .catch((error) => {
                        console.log("faild")
                        debugger

                    })
            } else {
                toast.error(apires.data.message, {
                    position: "top-center"
                })
            }

        })
            .catch((error) => {
                console.log("faild")
                toast.error("Token not Valid", {
                    position: "top-center"
                })
                setAdduseradmin(false)

            })
    }

    const deletelist = (e, record) => {
        let newselectitmes = [...selectitmes]
        if (e.target.checked) {
            newselectitmes.push({ UserId: record.userId, TelegramId: record.telegramId })
        }
        else {
            const index = newselectitmes.findIndex((currentvalue, index) => {
                return record.telegramId == currentvalue.TelegramId
            });

            if (index != null && index != undefined && index > -1) {

                newselectitmes.splice(index, 1)
            }
        }
        debugger
        setSelecteditmes(newselectitmes)
        console.log(selectitmes)

    }


    const deletetelegrmlist = () => {
        debugger
        telegramtokendelete.telegramtokendelet(selectitmes).then((apires) => {
            toast.info(apires.data.message, {
                position: "top-center"
            })
            setDeleteitmes(true)
        })
            .catch((error) => {
                console.log("faild")
                setDeleteitmes(false)
            })
    }

    const UpdateSt = (e, cItem, field) => {
        const newState = telegrmlist.map(obj => {

            if (cItem.telegramId == obj.telegramId) {
                let newObj = { ...obj };
                if (e == true) {
                    newObj[field] = "true"
                }
                else {
                    newObj[field] = "false"
                }
                return newObj;
            }
            return obj;
        });
        setTelegramlist(newState);
        console.log(newState)

        if (e == true) {
            debugger
            let usserid = userId
            let prams = null
            if (field == "status") {
                 prams = {UserId:usserid,TelegramId:cItem.telegramId,Status:"true",Type:"Message" }      
            }
            if (field == "channelStatus") {
                prams = {UserId:usserid,TelegramId:cItem.telegramId,Status:"true",Type:"Channel" }      
            }
            if (field == "groupStatus") {
                prams = {UserId:usserid,TelegramId:cItem.telegramId,Status:"true",Type:"Group" }      
               }

            telegrastatus.telegramstatuschange(prams).then((apires) => {
                debugger
                toast.info(apires.data.message, {
                    position: "top-center"
                })

            })
                .catch((error) => {
                    console.log("faild")
                    debugger
                })

        } else {
            let usserid = userId
            let prams = null
            if (field == "status") {
                 prams = {UserId:usserid,TelegramId:cItem.telegramId,Status:"false",Type:"Message" }      
            }
            if (field == "channelStatus") {
                prams = {UserId:usserid,TelegramId:cItem.telegramId,Status:"false",Type:"Channel" }      
            }
            if (field == "groupStatus") {
                prams = {UserId:usserid,TelegramId:cItem.telegramId,Status:"false",Type:"Group" }      
               }
            telegrastatus.telegramstatuschange(prams).then((apires) => {
                debugger
                toast.info(apires.data.message, {
                    position: "top-center"
                })
                setchanestatus(false)

            })
                .catch((error) => {
                    console.log("faild")
                    debugger
                })

        }


 

    }

    // const changestatus = (e,record) => {
    //     debugger

    //     if (e == true) {
    //         let usserid = https.userid
    //         let prams = {UserId:usserid,TelegramId:record.telegramId,Status:"true"}
    //         telegrastatus.telegramstatuschange(prams).then((apires) => {  
    //             debugger              
    //             toast.info(apires.data.message, {
    //                 position: "top-center"
    //             })  

    //         })
    //             .catch((error) => { 
    //                 console.log("faild")
    //                 debugger  

    //             })

    //     } else {
    //         let usserid = https.userid
    //         let prams = {UserId:usserid,TelegramId:record.telegramId,Status:"false"}
    //         telegrastatus.telegramstatuschange(prams).then((apires) => {    
    //             debugger      
    //             toast.info(apires.data.message, {
    //                 position: "top-center"
    //             })    
    //             setchanestatus(false)

    //         })
    //             .catch((error) => {
    //                 console.log("faild")
    //               debugger
    //             })

    //     }



    // }


    useEffect(() => {
        debugger
        let usserid = userId
        telegramtokenlist.telegramtokenlist(usserid).then((apires) => {
            debugger
            setTelegramlist(apires.data.data)
        })
            .catch((error) => {
                console.log("faild")
                debugger
            })
    }, []) //changestatuss, deleteitems, adduseradmin
    debugger
    
    const columns = [
        {
            title: "Active",
            dataIndex: "Active",
            align: "center",
            render: (_, record) =>
                telegrmlist.length >= 1 ? (
                    <Checkbox
                        onClick={(e) => { deletelist(e, record) }}
                    ></Checkbox>

                ) : null,
        },
        {
            title: 'Id',
            dataIndex: 'telegramId',
            align: "center"
        },
        {
            title: 'Userid',
            dataIndex: 'userId',
            align: "center",
            Hidden: true
        },
        {
            title: 'Name',
            dataIndex: 'name',
            align: "center"
        },
        {
            title: 'Bot Username',
            dataIndex: 'botUsername',
            align: "center"
        },
        {
            title: 'Bot Id',
            dataIndex: 'botId',
            align: "center"
        },
        {
            title: 'Created at',
            dataIndex: 'createDate',
            align: "center",
            width: '15%'


        },
        {
            title: 'Message Status ',
            dataIndex: 'status',
            align: "center",
            render: (_, record) =>
                telegrmlist.length >= 1 ? (
                    <Switch style={{backgroundColor: record.status == "true"  ? 'green' : 'orange'}}
                        checked={(record.status == "true") ? true : false}
                        // onChange={(e)=>{changestatus(e,record)}}
                        onChange={(e) => {UpdateSt(e, record, "status") }}
                    >
                    </Switch>
                )
                    : null
        },
        {
            title: 'Chanel Status ',
            dataIndex: 'channelStatus',
            align: "center",
            render: (_, record) =>
                telegrmlist.length >= 1 ? (
                    <Switch style={{backgroundColor: record.channelStatus == "true"  ? 'green' : 'orange'}}
                        checked={(record.channelStatus == "true") ? true : false}
                        // onChange={(e)=>{changestatus(e,record)}}
                        onChange={(e) => {UpdateSt(e, record, "channelStatus") }}
                    >
                    </Switch>
                )
                    : null
        },
        {
            title: 'Group Status ',
            dataIndex: 'groupStatus',
            align: "center",
            render: (_, record) =>
                telegrmlist.length >= 1 ? (
                    <Switch style={{backgroundColor: record.groupStatus == "true"  ? 'green' : 'orange'}}
                        checked={(record.groupStatus == "true") ? true : false}
                        // onChange={(e)=>{changestatus(e,record)}}
                        onChange={(e) => {UpdateSt(e, record, "groupStatus")}}
                    >
                    </Switch>
                )
                    : null
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Button type='primary' ghost onClick={(e) => {sendtelegram(record)}}>
                            Send Alert
                            <SendOutlined />
                        </Button>
                    </>
                )
            }
        },
        {
            title: 'Test',
            dataIndex: 'action',
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Button type='primary' ghost>
                            Test
                        </Button>
                    </>
                )
            }
        }
    ];



    useEffect(() => {
        if (runing) {
            debugger
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);

            };
        }
    },[])

    const sendtelegram = (reco) => {
        debugger
        setAlertsendemodel(true)
        setTelegramuser(reco.botUsername)
        setTelegramid(reco.telegramId)


    }


    const unalbeltelegram = () => {
        debugger
        setRuning(true)
        if (btnname == "Submit") {
            alert(btnname)
        }
        if (btnname == "Personal Message") {
            setTelegramstatus("Message")
            setCopycode(true)
            window.open("https://telegram.me/" + telegramuser + "?start")
        }
        if (btnname == "channel to all user") {
            setTelegramstatus("Channel")
            window.open("https://t.me/")
            setCopycode(false)
        }
        if (btnname == "Group to all user") {
            setTelegramstatus("Group")
            setCopycode(false)
            window.open("https://t.me/")
        }
    }


    useEffect(() => {
        debugger
        if (runing) {

            if (seconds == 59 || seconds == 39 || seconds == 19) {
                let params = { TelegramId: telegramid, Status: telgramstatus }
                TelegrammsgAuth.telegrammsgauth(params).then((apires) => {
                    debugger
                    setRuning(false)
                    setResponsmsg(apires.data.message)
                    setSeconds(59)
                    if (telgramstatus == "Message") {
                        setInputvalue(apires.data.data.chatId)
                    }
                    if (telgramstatus == "Channel") {
                        setInputvalue(apires.data.data.telegramChannel)
                    }

                    if (telgramstatus == "Group") {
                        setInputvalue(apires.data.data.telegramGroup)
                    }


                })
                    .catch((error) => {
                        debugger
                        console.log("faild")
                        setResponsmsg("Faild To connect")
                    })
            }

        }


    })

    const exitwindowalert = () => {
        setInputvalue("")
        setAlertsendemodel(false)
        toast.success("Code copided now pest it to URL", {
            position: "top-center"
        })
    }


    return (

        <div style={{marginTop:"100px"}}>
            <Modal
                style={{
                    top: 20,
                }}
                open={adduseradmin}
                okType='primary'
                okText="Submit"
                onOk={() => { postBotapi() }}
                onCancel={() => setAdduseradmin(false)}
                centered
                width={500}
                closable={false}
                cancelButtonProps={false}
            >
                <div className="Tlegramtoken" >
                    <form>
                        <div className='teletokenheder'>
                            <h1>BotFather Token</h1>
                            <p>Please enter your Telegram BotFather token Key</p>
                            <a href='https://telegram.me/BotFather'>BotFather Link</a>
                        </div>
                        <Input size="large" placeholder="Telegram Token" onChange={(e) => setTelegramtoken(e.target.value)} />

                    </form>


                </div>
            </Modal>
            <Modal
                style={{
                    top: 20,
                }}
                open={alertsendemodel}
                okType='primary'
                okText={btnname}
                onCancel={() => setAlertsendemodel(false)}
                onOk={unalbeltelegram}
                centered
                width={500}
                closable={false}
                cancelButtonProps={false}
            >
                <form>

                    <div className="row alertpopup" >
                        <div className="countdown-text-email">
                            {seconds > 0 || minutes > 0 ? (
                                <p>
                                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                </p>
                            ) : (
                                <p>{responsmsg}</p>
                            )}
                            {/* 
                                            <button className='btn btn-dark' type='button'
                                                disabled={seconds > 0 || minutes > 0 || resendemaileotp == true}
                                                style={{
                                                    color: seconds > 0 || minutes > 0 || resendemaileotp == false ? "#DFE3E8" : "#FF5630",
                                                }}

                                                onClick={unalbeltelegram}
                                            >
                                                Resend OTP
                                            </button> */}
                        </div>
                        <h5 style={{ color: "blueviolet" }}>Trading view alert bot</h5>
                        <div className='col-md-4 alertmassage' onClick={() => setbtname("Personal Message")}>
                            <input type="radio" name="radio" id="alertmassage" className='permsg'></input>
                            <div className='inputdiv'>
                                <h1><MailOutlined /></h1>
                                <h3>Message</h3>
                                <p>Notificiton in Personal Message</p>
                            </div>

                        </div>
                        <div className='col-md-4 alertchannel' onClick={() => setbtname("channel to all user")}>
                            <input type="radio" name="radio" id="alertchannel" className='permsg'></input>
                            <div className='inputdiv' >
                                <h1><UserOutlined /></h1>
                                <h3>Channel</h3>
                                <p>Notificiton in channel to all user</p>
                            </div>

                        </div>
                        <div className='col-md-4 alertgroup' onClick={() => setbtname("Group to all user")}>
                            <input type="radio" name="radio" id="alertmassage" className='permsg'></input>
                            <div className='inputdiv'>
                                <h1><TeamOutlined /></h1>
                                <h3>Group</h3>
                                <p>Notificiton in Group to all user</p>
                            </div>

                        </div>

                    </div>
                    <div className='row'>
                    <div className='col-md-5'>
                    <input hidden type='text' className='form-control' value={inputvalue} onChange={(e) => { setInputvalue(e.target.value) }} style={{ color: "blueviolet" }}></input>
                    </div>
                    <div className='col-md-2'>
                        <CopyToClipboard text={inputvalue}>
                            <Button hidden={copycode} type='button' placeholder='copy the code' onClick={() => { exitwindowalert() }}  size="large" icon={<CopyOutlined />} >Click to Copy Code </Button>
                        </CopyToClipboard>
                    </div>
                    </div>

                </form>
            </Modal>
            <div
                style={{
                    marginBottom: 16,
                }}
            >
                <div className='telegrambtngrp'>
                    <Space className="site-button-ghost-wrapper" wrap>
                        <Button type="primary" ghost onClick={() => {setAdduseradmin(true) }} >
                            Add Bot
                        </Button>
                        <Button type="primary" danger ghost onClick={(e) => { deletetelegrmlist() }}>
                            Delete
                        </Button>
                        <Button ghost>Refresh</Button>
                    </Space>
                </div>
            </div>

            <div className='telergaalert'>
            <Table
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={telegrmlist}
                scroll={{ x: true }}
            />
            </div>


            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};


export default TelegramAlerts