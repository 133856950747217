import { render } from '@testing-library/react';
import { Table, Popconfirm, Button, Popover } from 'antd';
import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from 'react-router-dom';
import * as actions from '../store/actions/Index'
import Mmermershipplanservives from '../store/services/Membershiplanlistser'
import optianmemplaser from '../store/services/optianmemplaser'
import getplanforaddonstg from '../store/services/getplanforaddonstg'
import MembershipRenewPlan from '../store/services/renewplanser'
import Repaymentinv from '../store/services/repayinvservice'
import { ToastContainer, toast } from 'react-toastify';
import { dark } from '@mui/material/styles/createPalette';
import {DeleteOutlined } from '@ant-design/icons'
import invoicedelete from "../store/services/invoicedelete"
import ConfirmToast from './ConfirmToast';
import ConfirmrenewToast from './Confirmrenewtost';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineAddchart } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Statargeyplan from './Statargeyplan';
const ShowUserplan = () => {
debugger
	const dispatch = useDispatch();
	const [griddata, setGriddata] = useState([]);
	const [upadteinvoice, setUpdateinvoice] = useState(false)
	const [membershipcard, setMembershipcard] = useState([])
	const [membershipoptain, setMembershioptain] = useState([])
	const authState = useSelector(state => state.authState)
	const [ispaysucess,setIspaysucess] = useState(false)
	const [stglist, setStglist] = useState({})
	const [renewplanlist,setRenewplanlist] = useState([{}])
    const updatedonin = useSelector(state => state.updatedonin)
	const [changerecoerd,setChangerecoerd] = useState(false)
	const history = useHistory();
	const repayinvstatus = useSelector(state => state.repayinvstatus)
	const statrgychekoutreducer = useSelector(state => state.statrgychekoutreducer);
	let ckeoutamt = statrgychekoutreducer.totalamount
	const stgtotal = statrgychekoutreducer.sttotal
    const granttotal = statrgychekoutreducer.totalamount
	let pursta = statrgychekoutreducer.slist

	const userId = localStorage.getItem('userid')
	const [isDeleting, setIsDeleting] = useState(false);


	const addstatargey = (value) => {
		debugger
		dispatch(actions.addonstgmem())
		let uid = userId
		// let invoiceid =value.invId
		getplanforaddonstg.getplnforstg(uid).then((apiRes) => {
			debugger
			let planId = apiRes.data.data.planId
			let planAmt = apiRes.data.data.planAmt
			let planName = apiRes.data.data.planName
			let planDays = apiRes.data.data.planDays

			localStorage.setItem('planId',planId)
			localStorage.setItem('planAmt',planAmt)
			localStorage.setItem('planName',planName)
			localStorage.setItem('planDays',planDays)			
				
			let path = "/Statargeyplan";
			history.push(path);
			dispatch(actions.membershipoptain(planId, planAmt, planName, planDays))
			dispatch(actions.chekstgsttauesaddon())
			dispatch(actions.updateinvoice(0))
			localStorage.setItem('isaddon',true)
		})
			.catch((error) => {
				console.log("faild")
				
			})
	}

	const showdata = () => {
		debugger
		let uid = userId
		let invid = 0
		optianmemplaser.optmemrshipplan(uid,invid).then((apiRes) => {
			debugger
			setMembershioptain(apiRes.data.data)
		})
			.catch((error) => {
				console.log("faild")
			
			})
	}


	const renewuserplan = (value) => {

		dispatch(actions.postrenuvalplan())
		let invoiced = value.invId
		let userId = value.userId
		let planId = value.planId
		let planPrice = value.planPrice
		let planName = value.planName
		let planDays = value.planDays
		 let selectitmes = value.invDtls
		let params = { InvId: invoiced, UserId: userId }

		MembershipRenewPlan.memshiprenew(params).then((apiRes) => {
			if (apiRes.data.isSuccess) {
		
				let invoiceid = apiRes.data.data.invId
				let invoiceamount = apiRes.data.data.invTotAmt
			
				Repaymentinv.repayInv(userId,invoiced).then((apiRes) => {
			
					if (apiRes.data.isSuccess) {
				
						setRenewplanlist(apiRes.data.data)					
						let renuinvlist = apiRes.data.data
				
						let renewlist = renuinvlist.map((item)=>{
					
							 selectitmes = item.invDtls		
						})
				
						let stgtotal = 0;
						let stgtotaltoatalamaount = 0;
						selectitmes.forEach(itm => {
							stgtotal = parseInt(stgtotal) + parseInt(itm.straTotalamt)
						})
						dispatch(actions.satageyoptain(invoiceamount, selectitmes, stgtotal))
						dispatch(actions.invoicepostsuccess(invoiceid,invoiceamount))
						dispatch(actions.repayementinvoicestatus())
						setIspaysucess(true)
			
						if (invoiceid != null) {
							let path = "/Checkout";
							history.push(path);					
						toast.info("Request Sumbmited sucessfully", {
							position:"top-center"
						})
						}				
					} else {
						toast.error("Faild To process!", {
							position:"top-center"
						})
						
					}
				})
					.catch((error) => {
						console.log("faild")
				
		
					})
				
				dispatch(actions.invoicepostsuccess(invoiceid,invoiceamount))
				dispatch(actions.membershipoptain(planId, planPrice, planName, planDays))
				setIspaysucess(true)
				let path = "/Checkout";
				history.push(path);

				toast.info("Request Sumbmited sucessfully", {
					position:"top-center"
				})				
			} else {
				toast.error("Faild To process!", {
					position:"top-center"
				})
				
			}
		})
			.catch((error) => {
				console.log("faild")
				toast.error("No network Found!", {
					position: "top-center", 
				})
			})
	}


	const repayofinv = (value) => {

		dispatch(actions.repayementinvoice())
		let invoiced = value.invId
		let userId = value.userId
		let invoiceamount= value.invTotAmt
		dispatch(actions.membershipoptain(value.planId, value.planPrice, value.planName, value.planDays))
		// let params = { InvId: invoiced, UserId: userId }

		Repaymentinv.repayInv(userId,invoiced).then((apiRes) => {
			debugger
			if (apiRes.data.isSuccess) {
		
				setRenewplanlist(apiRes.data.data)
				console.log(renewplanlist)
				let invoiceid = null
				let invoiceamount = null
				let subtotal = null
				let selectitmes = null
				let total = null
	
				let repaylistinv = apiRes.data.data
				let repaylist = repaylistinv.map((item)=>{
		
					 invoiceid = item.invId
					 invoiceamount=item.invTotAmt
					 selectitmes = item.invDtls
				})
		
				let stgtotal = 0;
				let stgtotaltoatalamaount = 0;
				selectitmes.forEach(itm => {
					stgtotal = parseInt(stgtotal) + parseInt(itm.straTotalamt)
				})
				dispatch(actions.satageyoptain(invoiceamount, selectitmes, stgtotal))
				dispatch(actions.invoicepostsuccess(invoiceid,invoiceamount))
		
				dispatch(actions.repayementinvoicestatus())
				setIspaysucess(true)
	
				if (invoiceid != null) {
					let path = "/Checkout";
					history.push(path);					
				toast.info("Request Sumbmited sucessfully", {
					position:"top-center"
				})
				showdata()
				}				
			} else {
				toast.error("Faild To process!", {
					position:"top-center"
				})
				
			}
		})
			.catch((error) => {
				console.log("faild")
		

			})
	}
 
	const deleteinv = (record) => {
	debugger
		const invoiced = record.invId;
		setIsDeleting(true);
		invoicedelete.invoicedel(invoiced).then((apiRes) => {
			toast.success("Record deleted successfully", {
			  position: "top-center"
			});
			showdata();
		  })
		  .catch((error) => {
			toast.error("Failed to delete record", {
			  position: "top-center"
			});
		  })
		  .finally(() => {
			setIsDeleting(false);
		  });

	}

	const updatemembership = (value) => {

		let invoiceid = value.invId
		dispatch(actions.updateinvoice(value.invId))
		setUpdateinvoice(true)
		dispatch(actions.chekstgsttaues())
	}


	useEffect(() => {
	debugger
		dispatch(actions.getmemershipplanlist())
		let uid = userId
		let invid = 0
		optianmemplaser.optmemrshipplan(uid,invid).then((apiRes) => {
		debugger
			// console.log(apiRes.data.data)
			setMembershioptain(apiRes.data.data)
			// console.log("Testant", apiRes.data.data)
			// console.log("membershipoptain:", membershipoptain)
		})
			.catch((error) => {
				console.log("faild")
			
			})

	}, [])

	const handeladd = (value) => {
		alert(value.invId)
	}


	const showConfirmationdelete = (value) => {
		toast(
		  <ConfirmToast
			onConfirm={() => {
			  toast.dismiss();
			  deleteinv(value);
			}}
			onCancel={() => {
			  toast.dismiss();
			}}
		  />,
		  {
			position: "top-center",
			autoClose: false,
			closeOnClick: false,
			closeButton: false,
		  }
		);
	  };

	  const showConfirmationrenew = (value) => {
		toast(
		  <ConfirmrenewToast
			onConfirm={() => {
			  toast.dismiss();
			  renewuserplan(value);
			}}
			onCancel={() => {
			  toast.dismiss();
			}}
		  />,
		  {
			position: "top-center",
			autoClose: false,
			closeOnClick: false,
			closeButton: false,
		  }
		);
	  };



	const colums = [
		{
			title: "Invoice",
			dataIndex: "invId",
			align: "center",
			defaultSortOrder: 'descend',
			width:'5%',
			sorter: {
				compare: (a, b) => a.invId - b.invId,
				multiple: 1,
			}
		},
		{
			title: "Date",
			dataIndex: "invDate",
			align: "center",
			width:'10%',
		},
		{
			title: "Plan active",
			dataIndex: "planName",
			align: "center",
			width:'10%',

		},
		{
			title: "Plan Days",
			dataIndex: "planDays",
			align: "center",
			width:'5%',
		},
		{
			title: "Expiery on",
			dataIndex: "expDate",
			align: "center",
			width:'10%',
		},
		{
			title: "Amount",
			dataIndex: "invTotAmt",
			align: "center",
			width:'8%',
		},
		{
			title: "Remaning Days",
			dataIndex: "rDays",
			align: "center",
			width:'5%',
		},
		{
			title: "Statuss",
			dataIndex: "paymentStatu",
			align: "center",
			width:'5%',
		},

		{
			title: "Add Strategy",
			dataIndex: "action",
			align: "center",
			width:'5%',
		
			render: (_, record) =>
				modifydata.length >= 1 ? (									
					<Button className='editplan' type='ghost' onClick={() => addstatargey(record)} disabled={record.paymentStatu != "Successful" || record.maxInvId !== record.invId}>
						<MdOutlineAddchart/>
					</Button>
					
				) : null,
		},

		// {
		// 	title: "Renewal",
		// 	dataIndex: "action",
		// 	align: "center",
		// 	render: (_, record) =>
		// 		modifydata.length >= 1 ? (

		// 			<Button type='primary' onClick={() => showConfirmationrenew(record)} disabled={record.paymentStatu != "Successful" || record.maxInvId !== record.invId} >
		// 				Renewal
		// 			</Button>
			
		// 		) : null,
		// },

		{
			title: "Repayment",
			dataIndex: "action",
			align: "center",
			width:'5%',	
			render: (_, record) =>
				modifydata.length >= 1 ? (
	
					<Button className='editplan' type='ghost' onClick={()=>repayofinv(record)} disabled={record.paymentStatu =='Successful' || record.maxInvId !== record.invId } >
							<MdPayments/>
						</Button>						
				) : null,
		},
		{
			title: "Delete",
			dataIndex: "action",
			align: "center",
			width:'5%',
			render: (_, record) =>
				modifydata.length >= 1 ? (
	
					<Button className='editplan' type='ghost' onClick={()=>showConfirmationdelete(record)} disabled={record.paymentStatu =='Successful'} >
					<AiFillDelete/>
						</Button>						
				) : null,
		},

	]
	
	const modifydata = membershipoptain.map(({ ...item }) => ({
		...item,
		key: item.invId,
	}))

	return (
		<div>

			<Table
				columns={colums}
				dataSource={modifydata}
				bordered
				scroll={{ x: true}}
				pagination={{ pageSize:6 }}
			/>

			<ToastContainer
				position="top-left"
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"

			/>
		
		</div>
	)

}

export default ShowUserplan